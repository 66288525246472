body {
  margin: 0;
  font-family: "Mostra Nuova W05 Regular";
  background-color: #0C344A;
  background-image: url(./Components/fondEcran.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
}



@media only screen and (orientation:landscape) {
  #turn {
    display: none;
  }
}