.rondAcceuil {
  position: relative;
  margin-top: 2vw;
  width: 50vw;
  height: 50vw;
  border-radius: 50%;
  background-color: #0C344A;
}

.divPrincipal {
  display: flex;
  justify-content: center;
}

.ensembleTexte {
  padding-top: 30%;
  text-align: center;
}

.cercleBlanc {
  position: absolute;
  left: 0.8vw;
  top: 0.8vw;
  border-radius: 50%;
  border: 0.2vw solid white;
  width: 48vw;
  height: 48vw;
  background-color: transparent;
}

.ligneBlancheSup {
  position: absolute;
  left: 17vw;
  top: 8vw;
  width: 16vw;
  border-top: 0.2vw solid white;
}

.ligneBlancheInf {
  position: absolute;
  left: 17vw;
  bottom: 8vw;
  width: 16vw;
  border-top: 0.2vw solid white;
}

.rondEnter {
  position: fixed;
  right: 1vw;
  bottom: 1vw;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondPres {
  position: absolute;
  top: 3vw;
  left: 22vw;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondHome {
  position: absolute;
  top: 20vw;
  left: 80vw;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rondReal {
  position: absolute;
  top: 33vw;
  left: 15vw;
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondInfo {
  position: absolute;
  top: 27vw;
  left: 50vw;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondCont {
  position: absolute;
  top: 10vw;
  left: 60vw;
  width: 13vw;
  height: 13vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondConnecter {
  position: absolute;
  top: 25vw;
  left: 10vw;
  width: 28vw;
  height: 28vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rondAcheter {
  position: absolute;
  top: 5vw;
  left: 38vw;
  width: 21vw;
  height: 21vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rondDecider {
  position: absolute;
  top: 30vw;
  left: 50vw;
  width: 22vw;
  height: 22vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rondTransformer {
  position: absolute;
  top: 7vw;
  left: 70vw;
  width: 27vw;
  height: 27vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rondPrix {
  position: absolute;
  top: 5vw;
  left: 13vw;
  width: 17vw;
  height: 17vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rondPhoto {
  position: absolute;
  top: 10vw;
  left: 13vw;
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ligneBlancheSupPhoto {
  position: absolute;
  top: 5vw;
  left: 8.5vw;
  width: 7.5vw;
  border-top: 0.2vw solid white;
}

.ligneBlancheInfPhoto {
  position: absolute;
  bottom: 5vw;
  left: 8.5vw;
  width: 7.5vw;
  border-top: 0.2vw solid white;
}

.rondPlan {
  position: absolute;
  top: 20vw;
  left: 50vw;
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: #0C344A;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ligneBlancheSupPlan {
  position: absolute;
  top: 4.5vw;
  left: 7vw;
  width: 6vw;
  border-top: 0.2vw solid white;
}

.ligneBlancheInfPlan {
  position: absolute;
  bottom: 4.5vw;
  left: 7vw;
  width: 6vw;
  border-top: 0.2vw solid white;
}

.cerclePhoto {
  position: absolute;
  left: 0.5vw;
  top: 0.5vw;
  border-radius: 50%;
  border: 0.2vw solid white;
  width: 23.6vw;
  height: 23.6vw;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cerclePlan {
  position: absolute;
  left: 0.5vw;
  top: 0.5vw;
  border-radius: 50%;
  border: 0.2vw solid white;
  width: 18.6vw;
  height: 18.6vw;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (orientation:portrait) {
  .rondAcceuil {
    position: relative;
    margin-top: 30vw;
    width: 50vw;
    height: 50vw;
    border-radius: 50%;
    background-color: #0C344A;
  }
  .rondEnter {
    position: fixed;
    right: 10vw;
    bottom: 20vw;
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondPres {
    position: absolute;
    top: 10vw;
    left: 22vw;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondHome {
    position: absolute;
    top: 30vw;
    left: 80vw;
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rondReal {
    position: absolute;
    top: 73vw;
    left: 15vw;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondInfo {
    position: absolute;
    top: 95vw;
    left: 50vw;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondCont {
    position: absolute;
    top: 50vw;
    left: 50vw;
    width: 13vw;
    height: 13vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondPhoto {
    position: absolute;
    top: 30vw;
    left: 13vw;
    width: 25vw;
    height: 25vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rondPlan {
    position: absolute;
    top: 40vw;
    left: 50vw;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rondConnecter {
    position: absolute;
    top: 45vw;
    left: 10vw;
    width: 28vw;
    height: 28vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .rondAcheter {
    position: absolute;
    top: 25vw;
    left: 38vw;
    width: 21vw;
    height: 21vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rondDecider {
    position: absolute;
    top: 50vw;
    left: 50vw;
    width: 22vw;
    height: 22vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rondTransformer {
    position: absolute;
    top: 27vw;
    left: 70vw;
    width: 27vw;
    height: 27vw;
    border-radius: 50%;
    background-color: #0C344A;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}